import supabase from '../lib/supabase';

// Create a new bisogno
export const createBisogno = async (bisogno) => {
  try {
    // Inserisci il nuovo bisogno e restituisce il record creato
    const { data, error } = await supabase
      .from('bisogni')
      .insert(bisogno)
      .select();
    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error creating bisogno:', error);
    throw error;
  }
};

// Fetch all bisogni for the current user
export const getBisogni = async (userid) => {
  try {
    const { data, error } = await supabase
      .from('bisogni')
      .select('*')
      .eq('uuid', userid);
    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error fetching bisogni:', error);
    throw error;
  }
};
export const getBisogno = async (id, userid) => {
  try {
    const { data, error } = await supabase
      .from('bisogni')
      .select('*')
      .eq('id', id)
      .eq('uuid', userid); // Ensure the bisogno belongs to the current user
    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error fetching bisogni:', error);
    throw error;
  }
};

// Update a specific bisogno
export const updateBisogno = async (id, updates, userid) => {
  try {
    const { data, error } = await supabase
      .from('bisogni')
      .update(updates)
      .eq('id', id)
      .eq('uuid', userid); // Ensure the bisogno belongs to the current user
    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error updating bisogno:', error);
    throw error;
  }
};

// Delete a specific bisogno
export const deleteBisogno = async (id, userid) => {
  try {
    const { data, error } = await supabase
      .from('bisogni')
      .delete()
      .eq('id', id)
      .eq('uuid', userid); // Ensure the bisogno belongs to the current user
    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error deleting bisogno:', error);
    throw error;
  }
};

// Toggle the 'enabled' status of a specific bisogno
export const toggleBisogno = async (id, currentState, userid) => {
  try {
    const { data, error } = await supabase
      .from('bisogni')
      .update({ enabled: !currentState })
      .eq('id', id)
      .eq('uuid', userid); // Ensure the bisogno belongs to the current user
    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error toggling bisogno:', error);
    throw error;
  }
};
export const soddisfaBisogno = async (id, userid) => {
  try {
    const { data, error } = await supabase
      .from('bisogni')
      .update({ soddisfattoil:new Date() })
      .eq('id', id)
      .eq('uuid', userid); // Ensure the bisogno belongs to the current user
    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error toggling bisogno:', error);
    throw error;
  }
};

export const createDettaglio = async (dettaglio) => {
  try {
    const { data, error } = await supabase
      .from('dettagli')
      .insert(dettaglio);
    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error creating dettaglio:', error);
    throw error;
  }
};
