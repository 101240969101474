import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import AppAppBar from '../../components/AppAppBar';
import Hero2 from '../../components/Hero2';
import Features from '../../components/Features';
import getLPTheme from '../getLPTheme';

import { useSupabase } from '../../context/SupabaseContext';
import { useNavigate } from 'react-router-dom';
import * as BisController from '../../controllers/bisogniController';
import * as CatController from '../../controllers/categorieController';
import Container from '@mui/material/Container';
//import Grid from '@mui/material/Grid';
//import Card from '@mui/material/Card';
//import CardHeader from '@mui/material/CardHeader';
//import CardContent from '@mui/material/CardContent';
//import Avatar from '@mui/material/Avatar';

import Typography from '@mui/material/Typography';
//import { useTheme } from '@mui/system';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

function ToggleCustomTheme({ showCustomTheme, toggleCustomTheme }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100dvw',
        position: 'fixed',
        bottom: 24,
      }}
    >
      <ToggleButtonGroup
        color="primary"
        exclusive
        value={showCustomTheme}
        onChange={toggleCustomTheme}
        aria-label="Platform"
        sx={{
          backgroundColor: 'background.default',
          '& .Mui-selected': {
            pointerEvents: 'none',
          },
        }}
      >
        <ToggleButton value>
          <AutoAwesomeRoundedIcon sx={{ fontSize: '20px', mr: 1 }} />
          Custom theme
        </ToggleButton>
        <ToggleButton value={false}>Material Design 2</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}

ToggleCustomTheme.propTypes = {
  showCustomTheme: PropTypes.shape({
    valueOf: PropTypes.func.isRequired,
  }).isRequired,
  toggleCustomTheme: PropTypes.func.isRequired,
};

const Bisogni = ({ navigation }) => {
  //const theme = useTheme();

  const [mode, setMode] = React.useState('light');
  const [showCustomTheme, setShowCustomTheme] = React.useState(false);
  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const { session } = useSupabase();
  const [bisogni, setBisogni] = useState([]);

  const navigate = useNavigate();

  const loadBisogni = async () => {
    if (!session) return; // Controllo se la sessione è null
    try {
      const userid = session.user.id;
      const data = await BisController.getBisogni(userid);
      setBisogni(data);
    } catch (error) {
      console.error(error);
    }
  };



  useEffect(() => {
    loadBisogni();
  }, [session]);

  if (!session) {
    navigate('/login');
  }

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  //const toggleCustomTheme = () => {
  //  setShowCustomTheme((prev) => !prev);
  //};

  const handleFabClick = () => {
    navigate(`/bisogno/add`);
  };

  return (
    <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Hero2 />
      <Box sx={{ bgcolor: 'background.default' }}>
        {bisogni.length ? (
          <Features
            bodyTitle="Bisogni"
            contentText={
              <p>In questa pagina trovi l'elenco dei bisogni che hai inserito. <br />Clicca su un elemento per aggiornare la data di soddisfazione,<br />sulla freccia a destra per modificare i dati del bisogno</p>
            }
            items={bisogni}
            reload={loadBisogni}
          />
        ) : (
          <Container
            sx={{
              pt: { xs: 4, sm: 12 },
              pb: { xs: 8, sm: 16 },
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: { xs: 3, sm: 6 },
            }}
          >
            <Box
              sx={{
                width: { sm: '100%', md: '60%' },
                textAlign: { sm: 'left', md: 'center' },
              }}
            >
              <Typography component="h2" variant="h4" color="text.primary">
                Nessun Bisogno
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Clicca sul pulsante in basso per aggiungere i tuoi bisogni.
              </Typography>
            </Box>
          </Container>
        )}
      </Box>

      {/*<ToggleCustomTheme*/}
      {/*  showCustomTheme={showCustomTheme}*/}
      {/*  toggleCustomTheme={toggleCustomTheme}*/}
      {/*/>*/}

      <Fab
        color="primary"
        aria-label="add"
        onClick={handleFabClick}
        sx={{ position: 'fixed', bottom: 40, right: 40 }}
      >
        <AddIcon />
      </Fab>
    </ThemeProvider>
  );
};

export default Bisogni;
