import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import FacebookIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/X';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

//const logoStyle = {
//  width: '40px',
//  height: 'auto',
//};

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {'Copyright © '}
      <Link href="https://valorian.it/">Valorian&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  const navigate = useNavigate(); 


  const handleEnableNotifications = () => {
    navigate('/notifications');
  };


  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        //py: { xs: 2, sm: 8 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: { xs: '100%', sm: '60%' },
          }}
        >
          <Box sx={{ width: { xs: '100%' } }}>
            <Typography variant="body2" fontWeight={600} gutterBottom>
              Attiva le notifiche per un'esperienza personalizzata. Puoi modificare le impostazioni nella pagina dedicata.
            </Typography>
            <Typography variant="body2" color="text.secondary" mb={2}>
              Ricevi avvisi importanti e rimani aggiornato sui tuoi progressi.<br />
              <br />Attivando le notifiche push, potrai:

              <br /><b>Ricevere</b> promemoria: Non dimenticherai mai di aggiornare lo stato di soddisfazione dei bisogni.
              <br /><b>Monitorare</b> i tuoi progressi: Sarai sempre aggiornato sui tuoi risultati e potrai festeggiare i tuoi successi.
              <br /><b>Scoprire</b> nuove funzionalità: Sarai il primo a conoscere le novità della nostra app.
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                pr: 2,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{ flexShrink: 0 }}
                endIcon={<NotificationsActiveIcon />}
                onClick={handleEnableNotifications}  // Naviga alla pagina delle notifiche
              >
                Voglio attivare le notifiche
              </Button>
            </Box>
          </Box>
        </Box>
      {/*  <Box*/}
      {/*    sx={{*/}
      {/*      display: { xs: 'none', sm: 'flex' },*/}
      {/*      flexDirection: 'column',*/}
      {/*      gap: 1,*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Typography variant="body2" fontWeight={600}>*/}
      {/*      Product*/}
      {/*    </Typography>*/}
      {/*    <Link color="text.secondary" href="#">*/}
      {/*      Features*/}
      {/*    </Link>*/}
      {/*    <Link color="text.secondary" href="#">*/}
      {/*      Testimonials*/}
      {/*    </Link>*/}
      {/*    <Link color="text.secondary" href="#">*/}
      {/*      Highlights*/}
      {/*    </Link>*/}
      {/*    <Link color="text.secondary" href="#">*/}
      {/*      Pricing*/}
      {/*    </Link>*/}
      {/*    <Link color="text.secondary" href="#">*/}
      {/*      FAQs*/}
      {/*    </Link>*/}
      {/*  </Box>*/}
      {/*  <Box*/}
      {/*    sx={{*/}
      {/*      display: { xs: 'none', sm: 'flex' },*/}
      {/*      flexDirection: 'column',*/}
      {/*      gap: 1,*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Typography variant="body2" fontWeight={600}>*/}
      {/*      Company*/}
      {/*    </Typography>*/}
      {/*    <Link color="text.secondary" href="#">*/}
      {/*      About us*/}
      {/*    </Link>*/}
      {/*    <Link color="text.secondary" href="#">*/}
      {/*      Careers*/}
      {/*    </Link>*/}
      {/*    <Link color="text.secondary" href="#">*/}
      {/*      Press*/}
      {/*    </Link>*/}
      {/*  </Box>*/}
      {/*  <Box*/}
      {/*    sx={{*/}
      {/*      display: { xs: 'none', sm: 'flex' },*/}
      {/*      flexDirection: 'column',*/}
      {/*      gap: 1,*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Typography variant="body2" fontWeight={600}>*/}
      {/*      Legal*/}
      {/*    </Typography>*/}
      {/*    <Link color="text.secondary" href="#">*/}
      {/*      Terms*/}
      {/*    </Link>*/}
      {/*    <Link color="text.secondary" href="#">*/}
      {/*      Privacy*/}
      {/*    </Link>*/}
      {/*    <Link color="text.secondary" href="#">*/}
      {/*      Contact*/}
      {/*    </Link>*/}
      {/*  </Box>*/}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 2, sm: 2 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <div>
          <Link color="text.secondary" href="#">
            Privacy Policy
          </Link>
          <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" href="#">
            Terms of Service
          </Link>
          <Copyright />
        </div>
        <Stack
          direction="row"
          justifyContent="left"
          spacing={1}
          useFlexGap
          sx={{
            color: 'text.secondary',
          }}
        >
          <IconButton
            color="inherit"
            href="https://github.com/mui"
            aria-label="GitHub"
            sx={{ alignSelf: 'center' }}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://x.com/MaterialUI"
            aria-label="X"
            sx={{ alignSelf: 'center' }}
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.linkedin.com/company/mui/"
            aria-label="LinkedIn"
            sx={{ alignSelf: 'center' }}
          >
            <LinkedInIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}
