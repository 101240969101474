import supabase from '../lib/supabase';

// Helper function to get the current user
export const getCurrentUser = async () => {
  try {
    const { user, error } = await supabase.auth.getUser();
    if (error) throw error;
    return user;
  } catch (error) {
    console.error('Error fetching current user:', error);
    throw error;
  }
};
// Create a new category
export const createCategoria = async (category) => {
  try {
    const { data, error } = await supabase
      .from('categorie')
      .insert(category)
      .select();
    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Errore in createCategoria:', error);
    throw error;
  }
};

// Fetch all categories
export const getCategorie = async () => {
  try {
    const { data, error } = await supabase
      .from('categorie')
      .select('*');
    if (error) throw error;
    return data;
  } catch (error) {
    console.error(' Errore in getCategorie:', error);
    throw error;
  }
};
export const getCategoria = async (id) => {
  try {
    const { data, error } = await supabase
      .from('categorie')
      .select('*')
      .eq('id', id);
    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Errore in getCategoria:', error);
    throw error;
  }
};

// Update a specific category
export const updateCategoria = async (id, updates) => {
  try {
    const { data, error } = await supabase
      .from('categorie')
      .update(updates)
      .eq('id', id);
    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error updating category:', error);
    throw error;
  }
};

// Delete a specific category
export const deleteCategoria = async (id) => {
  try {
    const { data, error } = await supabase
      .from('categorie')
      .delete()
      .eq('id', id);
    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error deleting category:', error);
    throw error;
  }
};

export const getBisInCat = async () => {
  try {
    const { data, error } = await supabase
      .from('bisincat')
      .select('*');
    if (error) throw error;
    return data;
  } catch (error) {
    console.error('contrtoller- Errore nel recupero di bisincat :', error);
    throw error;
  }
};
export const getAssociazioni = async (bisognoid) => {
  try {
    const { data, error } = await supabase
      .from('bisincat')
      .select('categoriaid')
      .eq('bisognoid', bisognoid);
    console.log('getAssociazioni controller:', data)
    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Errore in getAssociazioni:', error);
    throw error;
  }
};
export const deleteAssociazioni = async (bisognoid, userid) => {
  try {
    const { data, error } = await supabase
      .from('bisincat')
      .delete()
      .eq('bisognoid', bisognoid)
      .eq('uuid', userid);
    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Errore in deleteAssociazioni:', error);
    throw error; // Rilancia l'errore per gestirlo nel componente chiamante
  }
};

export const createAssociazioni = async (bisognoid, categoriaid, userid) => {
  try {
    const { data, error } = await supabase
      .from('bisincat')
      .insert({
        bisognoid: bisognoid,
        categoriaid: categoriaid,
        uuid: userid,
      });
    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Errore in createAssociazioni:', error);
    throw error; // Rilancia l'errore per gestirlo nel componente chiamante
  }
};

export const updateAssociazioni = async (bisognoid, categoriaid, userid) => {
  try {
    await deleteAssociazioni(bisognoid, userid);
    const data = await createAssociazioni(bisognoid, categoriaid, userid);
    console.log('135 Dati restituiti da createAssociazioni:', data); // Aggiungi questo log per debug
    return data;
  } catch (error) {
    console.error('Errore in updateAssociazioni:', error);
    throw error;
  }
};

export const aggiornaAssociazioni = async (bisognoid, categoriaid, userid) => {
  try {
    const result = await updateAssociazioni(bisognoid, categoriaid, userid);
    console.log('Associazioni aggiornate:', result);
    return result; // Ritorna il risultato se tutto va bene
  } catch (error) {
    console.error('Errore in aggiornaAssociazioni:', error);
    throw error; // Rilancia l'errore per gestirlo nel componente chiamante
  }
};
