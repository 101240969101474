import React, { useState } from 'react';
import { useSupabase } from '../../context/SupabaseContext';
import { useNavigate } from 'react-router-dom';
import { IconButton, InputAdornment, Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Box, Typography, Container } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      <p>
        Copyright &copy;&nbsp;&nbsp;
        <Link color="inherit" href="https://valorian.it/">
          Valorian.it
        </Link>
        &nbsp;&nbsp;
        {new Date().getFullYear()}
      </p>
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function SignIn() {
  const { supabase } = useSupabase();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  function validateEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }

  function validatePassword(password) {
    return password.length >= 6;
  }

  async function signInWithEmail(event) {
    event.preventDefault(); // Prevent form submission
    let valid = true;

    if (!validateEmail(email)) {
      setEmailError('Inserisci un indirizzo email valido.');
      valid = false;
    } else {
      setEmailError('');
    }

    if (!validatePassword(password)) {
      setPasswordError('La password deve essere di almeno 6 caratteri.');
      valid = false;
    } else {
      setPasswordError('');
    }

    if (!valid) return;

    setLoading(true);
    const { error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });

    if (error) {
      // Gestisci l'errore di autenticazione
      setEmailError('Errore di autenticazione. Controlla le credenziali.');
    } else {
      navigate('/');
    }
    setLoading(false);
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    if (validateEmail(event.target.value)) {
      setEmailError('');
    } else {
      setEmailError('Inserisci un indirizzo email valido.');
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    if (validatePassword(event.target.value)) {
      setPasswordError('');
    } else {
      setPasswordError('La password deve essere di almeno 6 caratteri.');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Accedi
          </Typography>
          <Box component="form" onSubmit={signInWithEmail} sx={{ mt: 1 }}>


            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Indirizzo email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={handleEmailChange}
                  helperText={emailError}
                  error={!!emailError}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  id="password"
                  autoComplete="current-password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={handlePasswordChange}
                  helperText={passwordError}
                  error={!!passwordError}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Ricordami"
            />
            <Grid item xs={12}>
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={loading}>Accedi</Button>
              <Button fullWidth variant="contained" color="inherit" onClick={() => navigate(-1)}>Annulla</Button>
            </Grid>

            <Grid container>
              <Grid item xs>
                <Link href="/recovery" variant="body2">
                  Password dimenticata?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/register" variant="body2">
                  {"Non hai un account? Registrati"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
