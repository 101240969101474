import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from 'react-router-dom';
import { useSupabase } from '../context/SupabaseContext';
import * as BisController from '../controllers/bisogniController';

export default function Features({ bodyTitle, contentText, items, reload }) {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState('');
  const navigate = useNavigate();
  const { session } = useSupabase();
  const [loading, setLoading] = useState(false);
  const [snackbarVisible, setSnackbarVisible] = useState(false); // Inizialmente false
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackColor, setSnackColor] = useState("success");

  //const handleItemClick = (index) => {
  //  setSelectedItemIndex(index);
  //};

  // Funzione per gestire il click sull'icona di navigazione
  const editBisogno = (event, itemId) => {
    event.stopPropagation(); // Previene la propagazione dell'evento al Card
    navigate(`/bisogno/edit/${itemId}`);
  };

  // Funzione di esempio per updateBisogno
  const updateBisogno = async (bisogno) => {
    setLoading(true);
    const today = new Date();
    const todayStart = new Date(today.setHours(0, 0, 0, 0));
    const bisognoUpdatedDate = new Date(bisogno.soddisfattoil);
    const bisognoUpdatedStart = new Date(
      bisognoUpdatedDate.setHours(0, 0, 0, 0),
    );
    if (bisognoUpdatedStart.getTime() === todayStart.getTime()) {
      showSnackbar(`Bisogno "${bisogno.nome}", lo hai gi� aggiornato per oggi`, "warning", 3000);
      setLoading(false);
      return;
    }

    const userid = session.user.id;
    await BisController.soddisfaBisogno(bisogno.id, userid);
    const dettaglio = {
      bisognoid: bisogno.id,
      soddisfattoil: new Date(),
      uuid: userid,
    };

    await BisController.createDettaglio(dettaglio);
    setLoading(false);
    reload();
    showSnackbar(`Bisogno "${bisogno.nome}", aggiornato con successo`, "success", 3000);
  };



  const showSnackbar = (message, color, timeout) => {
    setSnackbarMessage(message);
    setSnackColor(color);
    setSnackbarVisible(true);
    setTimeout(() => {
      setSnackbarVisible(false);
    }, timeout);
  };

  const determineCssClasses = (bisogno, theme) => {
    if (!bisogno) return "";
    const today = new Date();
    const date = new Date(bisogno.soddisfattoil);
    let cssClass = '';
    if (isSameDay(date, today)) {
      cssClass = "green";
    } else {
      cssClass = "gray";
    }
    return cssClass;
  };

  //const determineCssClasses2 = (bisogno, theme) => {
  //  if (!bisogno) return "";
  //  const today = new Date();
  //  const date = new Date(bisogno.soddisfattoil);

  //  if (updatedBisogni.has(bisogno.id) || isSameDay(date, today)) {
  //    return theme.palette.success.main; // Classe per il check verde
  //  } else {
  //    return theme.palette.text.secondary; // Classe per il check grigio
  //  }
  //};

  // Funzione ausiliaria per verificare se due date rappresentano lo stesso giorno
  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  return (
    <Container sx={{
      pt: { xs: 4, sm: 12 },
      pb: { xs: 8, sm: 16 },
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: { xs: 3, sm: 3 },
    }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          {bodyTitle}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {contentText}
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            useFlexGap
            sx={{ width: '100%' }}
          >
            {items.map((item, index) => (
              <Card
                key={index}
                variant="outlined"
                component={Button}
                onClick={() => updateBisogno(item)}
                sx={{
                  p: 2,
                  height: 'fit-content',
                  width: '100%',
                  background: 'none',

                  backgroundColor: (theme) => { determineCssClasses(item, theme) },
                  //backgroundColor: selectedItemIndex === index ? 'action.selected' : undefined,
                  borderColor: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index
                        ? 'primary.light'
                        : 'grey.200';
                    }
                    return selectedItemIndex === index ? 'primary.dark' : 'grey.800';
                  },
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 1.5,
                  }}
                >
                  <Box
                    sx={{
                      color: (theme) => {
                        if (theme.palette.mode === 'light') {
                          return 'red.300';
                        }
                        return 'red.700';
                      },
                    }}
                  >
                    <CheckIcon
                      fontSize="small"
                      sx={{
                        mt: '1px', ml: '2px',
                        color: (theme) => determineCssClasses(item, theme)
                      }}
                    />
                  </Box>

                  <Box sx={{ textTransform: 'none' }}>
                    <Typography
                      color="text.primary"
                      variant="body2"
                      fontWeight="bold"
                    >
                      {item.nome}
                    </Typography>
                    <Typography
                      color="gray"
                      variant="body2"
                    >
                      soddisfatto {new Date(item.soddisfattoil).toLocaleDateString("it-IT", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      ml: 'auto',
                      color: (theme) => {
                        if (theme.palette.mode === 'light') {
                          return 'grey.600';
                        }
                        return 'grey.300';
                      },
                    }}
                  >
                    <ChevronRightRoundedIcon
                      fontSize="small"
                      sx={{ mt: '1px', ml: '2px', mr: 2 }}
                      onClick={(event) => editBisogno(event, item.id)}
                    />
                  </Box>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100dvw',
          position: 'fixed',
          bottom: 24,
        }}
      >
        {snackbarVisible &&
          <Alert severity={snackColor}>{snackbarMessage}</Alert>
        }
        {loading &&
          <CircularProgress />
        }
      </Box>
    </Container>
  );
}
