import React, { useState } from 'react';
import { useSupabase } from '../../context/SupabaseContext';
import { Avatar, Button, CssBaseline, TextField, Link, Grid, Box, Typography, Container } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      <p>
        Copyright &copy;&nbsp;&nbsp;
        <Link color="inherit" href="https://valorian.it/">
          Valorian.it
        </Link>
        &nbsp;&nbsp;
        {new Date().getFullYear()}
      </p>
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function Recovery() {
  const { supabase } = useSupabase();
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [recoveryMessage, setRecoveryMessage] = useState('');

  function validateEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }

  const handleResetPassword = async (event) => {
    event.preventDefault();
    let valid = true;

    if (!validateEmail(email)) {
      setEmailError('Inserisci un indirizzo email valido.');
      valid = false;
    } else {
      setEmailError('');
    }

    if (!valid) return;

    setLoading(true);






    const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: 'http://localhost:3000/resetPassword',
    })
    if (error) {
      setRecoveryMessage('Error resetting password: ' + error.message);
    } else {
      setRecoveryMessage(`Abbiamo inviato una email con le istruzioni per reimpostare la password a ${email}. Controlla la casella Spam.`)
    }

    setLoading(false);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    if (validateEmail(event.target.value)) {
      setEmailError('');
    } else {
      setEmailError('Inserisci un indirizzo email valido.');
    }
  };


  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Box component="form" onSubmit={handleResetPassword} sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Indirizzo email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={handleEmailChange}
                  helperText={emailError}
                  error={!!emailError}
                />
              </Grid>
              <Grid item xs={12}>
                <p>
                  {recoveryMessage }
                </p>
              </Grid>

            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              Invia Reset Link
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/login" variant="body2">
                  Hai un account? Accedi
                </Link>
              </Grid>
            </Grid>

          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
