import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from '../components/AppAppBar';
import Hero from '../components/Hero';
import Hero2 from '../components/Hero2';
import Footer from '../components/Footer';


export default function Layout({ children, showHero, showHero2, showFooter}) {
  const [mode, setMode] = React.useState('light');
  const defaultTheme = createTheme({ palette: { mode } });
  
  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };


  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      {showHero && <Hero />}
      {showHero2 && <Hero2 />}
      <Box sx={{ bgcolor: 'background.default' }}>
        {children}
        {showFooter && <Footer />}
      </Box>
    </ThemeProvider>
  );
}