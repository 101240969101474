import React, {useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useSupabase } from '../context/SupabaseContext';

export default function Testimonial() {
  const navigate = useNavigate();
  const { session } = useSupabase();

  useEffect(() => {
    if (session) {
      navigate('/bisogni');
    }
  }, [])




  const handleStartClick = () => {  
    if(session) {
      navigate('/bisogni');
    } else
    navigate('/login');
  };

  return (
    <Container
      sx={{
        pt: { xs: 4, sm: 4 },
        pb: { xs: 4, sm: 10 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 1, sm: 2 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex' }}>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              flexGrow: 1,
              p: 1,
            }}
          >
            <CardContent>
              <Typography component="h2" variant="h6" color="text.primary">
                Ti sei mai sentito strano?
              </Typography>

              <Typography variant="body2" color="text.secondary">
                <p>
                  <br />
                  Hai mai avuto la percezione che le tue risposte a domande normali
                  hanno scaturito in te una risposta esagerata?
                  <br />
                  <br />
                  Ecco questa situazione potrebbe essere la manifestazione di un tuo
                  malessere dovuto alla insoddisfazione dei tuoi bisogni secondari,
                  importanti per una vita di relazione serena.
                  <br />
                  <br />
                  Valorian ti aiuta a mantenere sotto controllo la situazione, monitora
                  ogni bisogno e aiuta a rimediare al problema.
                  <br />
                  <br />
                  Come fare? Clicca sul nome di ogni bisogno, questo evento aggiorna il
                  livello dello stato generale.
                  <br />
                  <br />
                  Dopo un periodo di acquisizione dati ti sar possibile avere un quadro
                  della situazione
                </p>

              </Typography>
            </CardContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                pr: 2,
              }}
            >
              <Button variant="contained" color="primary" sx={{ flexShrink: 0 }} onClick={handleStartClick} >
                INIZIA
              </Button>

            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
