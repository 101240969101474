import React, { useState } from 'react';
import { callEnableCorsFunction } from '../../lib/supabaseService';

const SupabaseTestCors = () => {
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await callEnableCorsFunction(name);
      setMessage(data.message);
    } catch (error) {
      setMessage('Error calling function');
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        <button type="submit">Call Function</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default SupabaseTestCors;
