import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useSupabase } from '../../context/SupabaseContext';
import * as BisController from '../../controllers/bisogniController';
import * as CatController from '../../controllers/categorieController';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import AppAppBar from '../../components/AppAppBar';
import getLPTheme from '../getLPTheme';
import CircularProgress from '@mui/material/CircularProgress';
import HelpIcon from '@mui/icons-material/Help';
import { Modal as BaseModal } from '@mui/base/Modal';
import Fade from '@mui/material/Fade';
import { styled, css } from '@mui/system';


const ToggleCustomTheme = ({ showCustomTheme, toggleCustomTheme }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100dvw',
      position: 'fixed',
      bottom: 24,
    }}
  >
    <ToggleButtonGroup
      color="primary"
      exclusive
      value={showCustomTheme}
      onChange={toggleCustomTheme}
      aria-label="Platform"
      sx={{
        backgroundColor: 'background.default',
        '& .Mui-selected': {
          pointerEvents: 'none',
        },
      }}
    >
      <ToggleButton value>
        <AutoAwesomeRoundedIcon sx={{ fontSize: '20px', mr: 1 }} />
        Custom theme
      </ToggleButton>
      <ToggleButton value={false}>Material Design 2</ToggleButton>
    </ToggleButtonGroup>
  </Box>
);

ToggleCustomTheme.propTypes = {
  showCustomTheme: PropTypes.shape({
    valueOf: PropTypes.func.isRequired,
  }).isRequired,
  toggleCustomTheme: PropTypes.func.isRequired,
};

const BisognoPage = () => {
  const [mode, setMode] = useState('light');
  const [showCustomTheme, setShowCustomTheme] = useState(false);
  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme();

  const { session } = useSupabase();
  const navigate = useNavigate();
  const { bisognoid } = useParams();

  const [nome, setNome] = useState('');
  const [importanza, setImportanza] = useState(1);
  const [tolleranza, setTolleranza] = useState(1);
  const [colore, setColore] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [categorie, setCategorie] = useState([]);
  const [selectedCategoria, setSelectedCategoria] = useState(null);
  const [bisogno, setBisogno] = useState(null);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (bisognoid && session?.user?.id) {
      fetchBisogno();
    }
  }, [bisognoid, session]);

  useEffect(() => {
    if (bisogno) {
      getCategoriaForBisogno(bisogno.id);
      setInitialValues(bisogno);
    }
  }, [bisogno]);

  useEffect(() => {
    loadCategorie();
  }, []);

  const fetchBisogno = async () => {
    setLoading(true);
    try {
      const bisognoData = await BisController.getBisogno(bisognoid, session.user.id);
      if (bisognoData && bisognoData.length > 0) {
        setBisogno(bisognoData[0]);
      }
    } catch (error) {
      console.error('Errore nel caricamento del bisogno:', error);
    } finally {
      setLoading(false);
    }
  };

  const getCategoriaForBisogno = async (bisognoid) => {
    try {
      const associazione = await CatController.getAssociazioni(bisognoid);
      setSelectedCategoria(associazione[0].categoriaid);
    } catch (error) {
      console.error('Errore nel caricamento delle categorie associate:', error);
    }
  };
  const loadCategorie = async () => {
    try {
      const data = await CatController.getCategorie();
      setCategorie(data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const setInitialValues = (bisogno) => {
    setNome(bisogno.nome || '');
    setImportanza(bisogno.importanza || 1);
    setTolleranza(bisogno.tolleranza || 1);
    setColore(bisogno.colore || '');
  };

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const validateForm = () => {
    let errors = {};
    if (!nome) {
      errors.nome = 'Nome richiesto.';
    }
    if (!importanza) {
      errors.importanza = 'Importanza richiesta.';
    }
    if (!tolleranza) {
      errors.tolleranza = 'Tolleranza richiesta.';
    }
    setErrors(errors);
    setIsFormValid(Object.keys(errors).length === 0);
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission
    setLoading(true);
    validateForm();

    if (!isFormValid) {
      setLoading(false);
      return;
    }

    try {
      const userid = session.user.id;
      if (bisognoid) {
        //blocco per la modifica
        const update = {
          nome: String(nome),
          importanza: Number(importanza),
          tolleranza: Number(tolleranza),
          colore: colore || '',
          enabled: true,
        };
        //aggiorno il bisogno e le associazione
        await BisController.updateBisogno(bisognoid, update, userid);
        await CatController.aggiornaAssociazioni(bisognoid, Number(selectedCategoria), userid);
      } else {
        //blocco per il nuovo
        const create = {
          nome: String(nome),
          importanza: Number(importanza),
          tolleranza: Number(tolleranza),
          soddisfattoil: null,
          creatoil: new Date(),
          uuid: userid,
          enabled: true,
          colore: colore || '',
        };
        //creo il nuovo
        const newBisogno = await BisController.createBisogno(create);
        //recupero il record e aggiorno le associazioni
        await CatController.aggiornaAssociazioni(newBisogno[0].id, Number(selectedCategoria), userid);
      }
      navigate('/bisogni');
    } catch (error) {
      console.error('Error:', error);
      alert('Errore durante la chiamata API. Pagina Bisogno');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    setSelectedCategoria(event.target.value);
  };

  return (
    <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Box sx={{ bgcolor: 'background.default' }}>
        <Container sx={{ py: { xs: 12, sm: 12 }, marginTop: 5 }}>
          <Typography component="h2" variant="h5" color="text.primary">
            {bisognoid ? 'Modifica Bisogno' : 'Nuovo Bisogno'}
          </Typography>
          <Box component="form" width="100%" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography component="p" color="text.primary">Nome del bisogno</Typography>
                <TextField
                  fullWidth
                  value={nome}
                  required
                  id="nome"
                  name="nome"
                  variant="outlined"
                  autoComplete="nome"
                  autoFocus
                  onChange={(e) => {
                    setNome(e.target.value);
                    if (errors.nome) {
                      setErrors((prevErrors) => ({ ...prevErrors, nome: null }));
                    }
                  }}
                  error={Boolean(errors.nome)}
                  helperText={errors.nome}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography color="text.primary">Importanza per te in una scala da 1 a 10?</Typography>
                <Typography component="p" color="text.primary" style={{ textAlign: 'center', fontWeight: 'bold' }}>{importanza || 1}</Typography>
                <Slider
                  aria-label="Importanza steps"
                  value={importanza || 1}
                  onChange={(e) => setImportanza(e.target.value)}
                  step={1}
                  marks
                  min={1}
                  max={10}
                  valueLabelDisplay="auto"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography component="p" color="text.primary">Quanti giorni riesci a stare senza soddisfarlo?</Typography>
                <TextField
                  fullWidth
                  placeholder="numero dei giorni"
                  aria-label="numero di giorni"
                  value={tolleranza}
                  onChange={(e) => {
                    setTolleranza(e.target.value);
                    if (errors.tolleranza) {
                      setErrors((prevErrors) => ({ ...prevErrors, tolleranza: null }));
                    }
                  }}
                  type="number"
                  max="99"
                  error={Boolean(errors.tolleranza)}
                  helperText={errors.tolleranza}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography color="text.primary">
                  Associalo ad una categoria di bisogni &nbsp;&nbsp;
                  <HelpIcon size="small" color="secondary" variant="filled" onClick={() => handleOpen()} />
                </Typography>

                <FormControl>
                  <RadioGroup
                    column
                    aria-labelledby="categoria-radio-buttons-group"
                    name="categoria-radio-buttons-group"
                    value={selectedCategoria}
                    onChange={handleChange}
                  >
                    {categorie.map((categoria) => (
                      <FormControlLabel key={categoria.id} value={categoria.id} control={<Radio size="small" />} label={categoria.nome} />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid container direction="row" justifyContent="space-around" alignItems="center" marginTop={3}>
                <Button variant="outlined" color="inherit" onClick={() => navigate("/bisogni")}>Annulla</Button>
                <Button variant="contained" color="primary" type="submit" disabled={loading}>
                  {loading ? (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <CircularProgress size={24} sx={{ marginRight: 1 }} />
                      Invio in corso
                    </Box>
                  ) : (
                    bisognoid ? 'Aggiorna' : 'Aggiungi'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100dvw',
          position: 'fixed',
          bottom: 24,
        }}
      >
        {loading &&
          <CircularProgress />
        }
      </Box>
      <Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <ModalContent sx={{ width: '90%', maxWidth: '600px' }}>
          <h2 id="unstyled-modal-title" className="modal-title">
            I 6 bisogni di Antony Robbins
          </h2>
          <p id="unstyled-modal-description" className="modal-description">
            <b>Antony Robbins</b> ha fatto una lista di 6 bisogni fondamentali ponendoli tutti allo stesso piano, distinguendone 4 di base e 2 evolutivi e sottolineando come ogni persona attribuisca loro un'importanza in modo e misura differenti e, soprattutto, utilizzi mezzi e strumenti diversi per soddisfarli.

            <br />I primi quattro bisogni sono "i bisogni primari" o "bisogni fondamentali della personalit&#224;", e sono:
            <ol>
              <li><b>Bisogno di sicurezza:</b> rappresenta la necessit&#224; di ogni essere umano di sentirsi protetto, lontano dal dolore e dal pericolo. </li>
              <li>Bisogno di variet&#224;: rappresenta la necessit&#224; di cambiamento, di novit&#224;, di stimoli e avventura. Per combattere la noia data da un eccesso di sicurezza c�&#233; bisogno di cambiare costantemente ed evolversi.</li>
              <li>Bisogno di importanza: rappresenta la necessit� di sentirsi speciali, apprezzati, degni di amore e attenzione da parte degli altri.</li>
              <li>Bisogno di amore/unione o di appartenenza: rappresenta la necessit� di connessione con gli altri, di appartenenza ad una realt�, ad una famiglia, ad un gruppo di riferimento o a cercare una persona con cui condividere i propri obiettivi.</li>
              <li>Bisogno di crescita: rappresenta la necessit� di sviluppo a livello emotivo, spirituale e intellettuale. Soddisfa il bisogno dell�essere umano di progredire e migliorarsi. </li>
              <li>Bisogno di contribuire: rappresenta la necessit� di aiutare gli altri e di dare un proprio contributo positivo al mondo. � uno dei bisogni pi� elevati dell�essere umano e si basa sul cambio prospettiva: vedersi non solo come individui singoli ma come parte di un insieme. </li>
            </ol>
          </p>
        </ModalContent>
      </Modal>

    </ThemeProvider>
  );
};
const Backdrop = React.forwardRef((props, ref) => {
  const { open, ...other } = props;
  return (
    <Fade in={open}>
      <div ref={ref} {...other} />
    </Fade>
  );
});

Backdrop.propTypes = {
  open: PropTypes.bool,
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
};

const ModalContent = styled('div')(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
    padding: 24px;
    color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `,
);
const blue = {
  200: '#99CCFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0066CC',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

export default BisognoPage;
