import * as React from 'react';
import PropTypes from 'prop-types';
//import { initializeOneSignal } from '../services/OneSignalConfig';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
//import AppAppBar from '../components/AppAppBar';
import Hero from '../components/Hero';
import Testimonial from '../components/Testimonial';
import Footer from '../components/Footer';
import getLPTheme from './getLPTheme';

function ToggleCustomTheme({ showCustomTheme, toggleCustomTheme }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100dvw',
        position: 'fixed',
        bottom: 24,
      }}
    >
      <ToggleButtonGroup
        color="primary"
        exclusive
        value={showCustomTheme}
        onChange={toggleCustomTheme}
        aria-label="Platform"
        sx={{
          backgroundColor: 'background.default',
          '& .Mui-selected': {
            pointerEvents: 'none',
          },
        }}
      >
        <ToggleButton value>
          <AutoAwesomeRoundedIcon sx={{ fontSize: '20px', mr: 1 }} />
          Custom theme
        </ToggleButton>
        <ToggleButton value={false}>Material Design 2</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}

ToggleCustomTheme.propTypes = {
  showCustomTheme: PropTypes.shape({
    valueOf: PropTypes.func.isRequired,
  }).isRequired,
  toggleCustomTheme: PropTypes.func.isRequired,
};

export default function LandingPage() {
  const [mode, setMode] = React.useState('light');
  const [showCustomTheme, setShowCustomTheme] = React.useState(false);
  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  //const toggleCustomTheme = () => {
  //  setShowCustomTheme((prev) => !prev);
  //};
  //React.useEffect(() => {
  //  initializeOneSignal();
  //}, []);


  return (
    <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
      <CssBaseline />
      {/*<AppAppBar mode={mode} toggleColorMode={toggleColorMode} />*/}
      <Hero
        //titleLeft="Benvenuto su "
        titleRight="Valorian"
        textContent="e sai come stai"
      />
      <Box sx={{ bgcolor: 'background.default' }}>

        <Testimonial />
        <Footer />
      </Box>
    {/*  <ToggleCustomTheme*/}
    {/*    showCustomTheme={showCustomTheme}*/}
    {/*    toggleCustomTheme={toggleCustomTheme}*/}
    {/*  />*/}
    </ThemeProvider>
  );
}