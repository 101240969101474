import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

var firebaseConfig = {
  apiKey: "AIzaSyBybFctCXKpMPL-Bh3ac22Oj5nKVLccwK0",
  authDomain: "valorian-51b9c.firebaseapp.com",
  databaseURL: "https://valorian-51b9c-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "valorian-51b9c",
  storageBucket: "valorian-51b9c.appspot.com",
  messagingSenderId: "997883933690",
  appId: "1:997883933690:web:9962e1953ea520fd9baa43",
  measurementId: "G-NSD8C3ZN9Y"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (setTokenFound) => {
  return getToken(messaging, { vapidKey: 'BCTBQl3gpjIfW7DzSTuM7cXQrbwDhSN8eTLZ5wKMFVZNtt-SBazmqzU22ReH33nGeEfYzkXp5NICDe7JQ_YZR8Y' }).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });