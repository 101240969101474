import React, { useState } from 'react';
import { useSupabase } from '../../context/SupabaseContext';
import { useNavigate } from 'react-router-dom';
import { IconButton, InputAdornment, Avatar, Button, CssBaseline, TextField, Link, Grid, Box, Typography, Container } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import '../../customPhoneInput.css'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      <p>
        Copyright &copy;&nbsp;&nbsp;
        <Link color="inherit" href="https://valorian.it/">
          Valorian.it
        </Link>
        &nbsp;&nbsp;
        {new Date().getFullYear()}
      </p>
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function SignUp() {
  const { supabase } = useSupabase();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [snackbarVisible, setSnackbarVisible] = useState(false); // Inizialmente false
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackColor, setSnackColor] = useState("success");
  const [value, setValue] = useState()
  function validateEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }

  function validatePassword(password) {
    return password.length >= 6;
  }

  const signUpWithEmail = async (event) => {
    event.preventDefault();
    let valid = true;

    if (!validateEmail(email)) {
      setEmailError('Inserisci un indirizzo email valido.');
      valid = false;
    } else {
      setEmailError('');
    }

    if (!validatePassword(password)) {
      setPasswordError('La password deve essere di almeno 6 caratteri.');
      valid = false;
    } else {
      setPasswordError('');
    }

    if (!valid) return;

    setLoading(true);
    const { data: { session }, error } = await supabase.auth.signUp({
      email: email,
      password: password,
      options: {
        data: {
          username: username,
          phone: value,
        },
      },
    });

    if (error) {
      showSnackbar(`Errore durante il processo di registrazione`, "danger", 4000);
    } else {
      if (!session) {
        navigate('/bisogni');
      } else {
        navigate('/');
      }
    }
    setLoading(false);
  };

  const showSnackbar = (message, color, timeout) => {
    setSnackbarMessage(message);
    setSnackColor(color);
    setSnackbarVisible(true);
    setTimeout(() => {
      setSnackbarVisible(false);
    }, timeout);
  };


  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    if (validateEmail(event.target.value)) {
      setEmailError('');
    } else {
      setEmailError('Inserisci un indirizzo email valido.');
    }
  };
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    if (validatePassword(event.target.value)) {
      setPasswordError('');
    } else {
      setPasswordError('La password deve essere di almeno 6 caratteri.');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Nuovo Account
          </Typography>
          <Box component="form" onSubmit={signUpWithEmail} sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Indirizzo email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={handleEmailChange}
                  helperText={emailError}
                  error={!!emailError}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  id="password"
                  autoComplete="current-password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={handlePasswordChange}
                  helperText={passwordError}
                  error={!!passwordError}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <PhoneInput className="custom-phone-input"
                  margin="normal"
                  required
                  fullWidth
                  id="phone"
                  name="phone"
                  label="Numero di telefono"
                  autoComplete="phone"
                  defaultCountry="it"
                  placeholder="Inserisci il numero di telefono"
                  value={value}
                  onChange={setValue}
                  error={value ? (isValidPhoneNumber(value) ? undefined : 'Numero telefonico invalido') : 'Numero di telefono richiesto'} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  onChange={handleUsernameChange}
                  helperText={usernameError}
                  error={!!usernameError}
                />
              </Grid>
            </Grid>
            {snackbarVisible &&
              <Alert severity={snackColor}>{snackbarMessage}</Alert>
            }
            <Grid item xs={12}>
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={loading}>Registrami</Button>
              <Button fullWidth variant="contained" color="inherit" onClick={() => navigate(-1)}>Annulla</Button>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/login" variant="body2">
                  Hai un account? Accedi
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
