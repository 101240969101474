import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { SupabaseProvider } from "./context/SupabaseContext";

import ErrorPage from "./routes/error-page";
import Auth from "./routes/auth/auth";
import Bisogni from "./routes/bisogni/bisogni";
import Landing from "./routes/LandingPage";
import Bisogno from "./routes/bisogni/bisogno";
import Stato from "./routes/pages/stato";
import Settings from "./routes/pages/settings";
import Faq from "./routes/pages/faq";
import Login from "./routes/auth/login";
import Register from "./routes/auth/register";
import Recovery from "./routes/auth/recovery";
import ResetPassword from "./routes/auth/resetPassword";
import SupabaseTestCors from "./routes/pages/supabaseTestCors";
import AdminUsers from "./routes/admin/AdminUsers";
import Layout from "./routes/layout";
import Page from "./routes/page";
import NotificationSettings from './routes/account/NotificationSettings';

const router = createBrowserRouter([
  { path: "/", element: <Landing />, errorElement: <ErrorPage /> },
  { path: "/auth", element: <Auth /> },
  { path: "/login", element: <Login /> },
  { path: "/register", element: <Register /> },
  { path: "/recovery", element: <Recovery /> },
  { path: "/resetPassword", element: <ResetPassword /> },
  { path: "/bisogni", element: <Bisogni /> },
  { path: "/landing", element: <Landing /> },
  { path: "/bisogno/add/", element: <Bisogno /> },
  { path: "/bisogno/edit/:bisognoid", element: <Bisogno /> },
  { path: "/stato", element: <Stato /> },
  { path: "/settings", element: <Settings /> },
  { path: "/faq", element: <Faq /> },
  { path: "/supabaseTestCors", element: <SupabaseTestCors /> },
  { path: "/admin", element: <AdminUsers /> },
  { path: "/layout", element: <Layout /> },
  { path: "/page", element: <Page /> },
  { path: "/notifications", element: < NotificationSettings /> }
]);

const container = document.getElementById('root');
const root = createRoot(container); // Use 'createRoot' for React 18

root.render(
  <React.StrictMode>
    <SupabaseProvider>
      <RouterProvider router={router} >
        <App />
      </RouterProvider>
    </SupabaseProvider>
  </React.StrictMode>
);
