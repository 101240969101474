import React, { useState } from 'react';
import { fetchToken, onMessageListener } from '../../firebase';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';

function NotificationSettings() {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [isTokenFound, setTokenFound] = useState(false);

  const requestPermission = () => {
    fetchToken(setTokenFound);
  };

  onMessageListener().then(payload => {
    setNotification({ title: payload.notification.title, body: payload.notification.body });
    setShow(true);
    console.log(payload);
  }).catch(err => console.log('failed: ', err));

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShow(false);
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={requestPermission}>
        Abilita Notifiche
      </Button>
      {isTokenFound && <h1> Notifiche abilitate 👍🏻 </h1>}
      {!isTokenFound && <h1> Necessario richiedere abilitazione notifiche ❗️ </h1>}

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={show}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarContent
          style={{
            minWidth: 200,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          message={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                src="holder.js/20x20?text=%20"
                alt=""
                style={{ marginRight: '8px' }}
              />
              <div>
                <Typography variant="subtitle1" component="strong">
                  {notification.title}
                </Typography>
                <Typography variant="body2">
                  just now
                </Typography>
                <Typography variant="body2">
                  {notification.body}
                </Typography>
              </div>
            </div>
          }
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </Snackbar>
    </div>
  );
}

export default NotificationSettings;
