import * as React from 'react';
import Layout from './layout';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

export default function SomePage() {
  return (
    <Layout>

      <Container id="testimonials" sx={{ pt: { xs: 4, sm: 12 }, pb: { xs: 8, sm: 16 }, position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: { xs: 3, sm: 6 }, }}>
        <Box sx={{ width: { sm: '100%', md: '60%' }, textAlign: { sm: 'left', md: 'center' }, }}>
          <Typography component="h2" variant="h4" color="text.primary">
            titolo
          </Typography>
          <Typography variant="body1" color="text.secondary">
            contenuto sottotitolo
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} key={ 0} sx={{ display: 'flex' }}>
              <Card sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flexGrow: 1, p: 1, }}>
                <CardContent>
                  <Typography variant="body2" color="text.secondary">testimonials</Typography>
                </CardContent>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pr: 2, }}>
                  <CardHeader avatar="avatar" title="nome" subheader="occupazione" />
                </Box>
              </Card>
            </Grid>
        </Grid>
      </Container>

    </Layout>
  );
}
