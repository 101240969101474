const enableCorsFunctionUrl = 'https://iigdhrkfuqfbnxhqxogy.functions.supabase.co/enable-cors';

export const callEnableCorsFunction = async (name) => {
  try {
    const response = await fetch(enableCorsFunctionUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: name
      })
    });

    if (!response.ok) {
      throw new Error('Failed to call enable-cors function');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error calling enable-cors function:', error);
    throw error;
  }
};
