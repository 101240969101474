import logo from '../../icon.png';
import { useState, useEffect } from 'react'

import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import supabase from '../../lib/supabase';
import { useNavigate } from 'react-router-dom';

function LoginPage() {
  const [session, setSession] = useState(null)
  const navigate = useNavigate();

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })

    return () => subscription.unsubscribe()
  }, [])

  if (!session) {
    //navigate('/bisogni');
    return (<Auth supabaseClient={supabase} appearance={{ theme: ThemeSupa }} />)
  }
  else {
    return (
      <div>
        <p>Bentornato {session.user.email}!</p>
        <br/>
        <button onClick={()=>supabase.auth.signOut()}>Logout</button>
      </div>

    )
  }

  const handleLogout = async () => {
    await supabase.auth.signOut()
    navigate('/')
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        {!session ?
            <Auth
              supabaseClient={supabase}
              appearance={{ theme: ThemeSupa }}
              theme="light"
              providers={[] }
              localization={{
                variables: {
                  sign_in: {
                    email_label: 'Email',
                    password_label: 'Password',
                    email_input_placeholder: "esempio@email.com",
                    password_input_placeholder: "digita la password",
                    button_label: "Accedi",
                    loading_button_label: "Accesso in corso...",
                    social_provider_text: "Accedi con",
                    link_text:'Hai un account? Accedi'
                  },
                  sign_up: {
                    email_label: 'Email',
                    password_label: 'Password',
                    email_input_placeholder: "esempio@email.com",
                    password_input_placeholder: "scegli la password",
                    button_label: "Registrati",
                    loading_button_label: "Registrazione in corso...",
                    social_provider_text: "Registrati con",
                    link_text: "Non hai un account? Registrati"
                  },
                  forgotten_password: {
                    email_label: 'Email',
                    password_label: 'Password',
                    email_input_placeholder: "esempio@email.com",
                    button_label: "Invia email per il reset",
                    loading_button_label: "Invio in 'corso...",
                    link_text: "Password dimenticata",
                    confirm_text:"Controlla la tua email per il reset della password"
                  },
                },
              }}
            />
            :
            <div>
              Bentornato { session.user.email}!
              <br />
              <button onClick={handleLogout}>Logout</button>
            </div>
        }
      </header>
    </div>
  );


}

export default LoginPage;
